import React, {Component} from "react";
import {Button} from "reactstrap";
import {API_URL} from "../../constants";
import axios from "axios";
import {saveData} from "../../utils/Utils";

class DownloadAllButton extends Component {
    state = {
        buttonIcon: '📩',
        buttonText: 'Download all',
    };
    previousDisabledState;

    downloadAll = () => {
        this.previousDisabledState = this.props.disabled;
        this.props.disabled = true;
        this.setState({
            buttonIcon: '⌛',
            buttonText: 'Keep this tab open...',
        });
        axios.get(API_URL + "download_all", {responseType: 'blob'}).then(response => {
            this.props.disabled = this.previousDisabledState;
            this.setState({
                buttonIcon: '📩',
                buttonText: 'Download all',
            });
            const fileNameHeader = "content-disposition";
            let suggestedFileName = response.headers[fileNameHeader];
            suggestedFileName = suggestedFileName.split('=')[1];
            const effectiveFileName = (suggestedFileName === undefined
                ? "single-word-word-search-puzzles.zip"
                : suggestedFileName);
            saveData(response.data, effectiveFileName);
        });
    };

    render() {
        return (<Button onClick={() => this.downloadAll()} color="success" outline disabled={this.props.disabled}>
            {this.state.buttonIcon} {this.state.buttonText}
        </Button>);
    }
}

export default DownloadAllButton;
