import React, {Component, Fragment} from "react";
import {Button, Col, Container, Form, FormControl, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {login} from "./LoginActions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Header from "../Header";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: this.props.auth.username,
            password: ""
        };
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    onLoginClick = e => {
        e.preventDefault();
        const userData = {
            username: this.state.username,
            password: this.state.password
        };
        this.props.login(userData, "/dashboard");
    };

    render() {
        return (
            <Fragment>
                <Header/>
                <Container>
                    <Row>
                        <Col md="4">
                            <h1>Login</h1>
                            <Form onSubmit={this.onLoginClick}>
                                <Form.Group className="mb-3" controlId="usernameId">
                                    <Form.Label>User name</Form.Label>
                                    <Form.Control
                                        isInvalid={this.props.auth.usernameError
                                            || this.props.auth.nonFieldErrors}
                                        type="text"
                                        name="username"
                                        placeholder="Enter user name"
                                        value={this.state.username}
                                        onChange={this.onChange}
                                    />
                                    <FormControl.Feedback type="invalid" style={{whiteSpace: 'pre-wrap'}}>
                                        {Array.isArray(this.props.auth.usernameError)
                                            ? this.props.auth.usernameError.join("\r\n")
                                            : this.props.auth.usernameError}
                                    </FormControl.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="passwordId">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        isInvalid={this.props.auth.passwordError
                                            || this.props.auth.nonFieldErrors}
                                        type="password"
                                        name="password"
                                        placeholder="Enter password"
                                        value={this.state.password}
                                        onChange={this.onChange}
                                    />
                                    <Form.Control.Feedback type="invalid" style={{whiteSpace: 'pre-wrap'}}>
                                        {Array.isArray(this.props.auth.passwordError)
                                            ? this.props.auth.passwordError.join("\r\n")
                                            : this.props.auth.passwordError}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Login
                                </Button>

                                <p className="mt-2">
                                    Don't have account? <Link to="/signup">Signup</Link>
                                </p>

                                <p className="mt-2">
                                    <Link to="/password-reset">Forgot your password?</Link>
                                </p>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

Login.propTypes = {
    login: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {
    login
})(withRouter(Login));
