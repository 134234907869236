import {LOGIN_ERROR, SET_CURRENT_USER, SET_TOKEN, SET_USERNAME, UNSET_CURRENT_USER} from "./LoginTypes";

const initialState = {
    isAuthenticated: false,
    user: {},
    token: "",
    username: "",
};

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload
            };
        case SET_CURRENT_USER:
            return {
                ...state,
                user: action.payload
            };
        case UNSET_CURRENT_USER:
            return initialState;
        case LOGIN_ERROR:
            const errorState = {
                usernameError: "",
                passwordError: "",
                isSubmitted: false
            };
            if (action.errorData.hasOwnProperty("username")) {
                errorState.usernameError = action.errorData["username"];
            }
            if (action.errorData.hasOwnProperty("password")) {
                errorState.passwordError = action.errorData["password"];
            }
            if (action.errorData.hasOwnProperty("non_field_errors")) {
                errorState.nonFieldErrors = action.errorData["non_field_errors"];
            }
            return errorState;
        case SET_USERNAME:
            return {
                ...state,
                username: action.payload
            };
        default:
            return state;
    }
};
