import React, {Component} from "react";
import {Button, ButtonGroup} from "reactstrap";
import ConfirmRemovalModal from "./ConfirmRemovalModal";
import axios from "axios";
import {saveData} from "../../utils/Utils";
import {formatDistance} from 'date-fns'
import GoogleFontLoader from "react-google-font-loader";

const FilterableTable = require('react-filterable-table');

class GridList extends Component {
    constructor(props) {
        super(props);
        this.tableMessage = "No words added yet.";
    }

    downloadGrid = link => {
        const url = new URL(link);
        axios.get(url.pathname, {responseType: 'blob'}).then(response => {
            const suggestedFileName = link.split('/').pop();
            const effectiveFileName = (suggestedFileName === undefined
                ? "single-word-word-search.png"
                : suggestedFileName);
            saveData(response.data, effectiveFileName);
        });
    };

    render() {
        const grids = this.props.grids;

        const fields = [
            {name: 'id', displayName: "ID", inputFilterable: true, sortable: true},
            {name: 'createdAt', displayName: "Added At", inputFilterable: true, exactFilterable: true, sortable: true},
            {name: 'word', displayName: "Word", inputFilterable: true, exactFilterable: true, sortable: true},
            {name: 'status', displayName: "Status", inputFilterable: true, exactFilterable: true, sortable: true},
            {name: 'rows', displayName: "Rows", inputFilterable: true, exactFilterable: true, sortable: true},
            {name: 'columns', displayName: "Columns", inputFilterable: true, exactFilterable: true, sortable: true},
            {
                name: 'font',
                displayName: "Font",
                inputFilterable: true,
                exactFilterable: true,
                sortable: true,
                render: this.renderFont
            },
            {
                name: 'color',
                displayName: "Color",
                inputFilterable: true,
                exactFilterable: true,
                sortable: true,
                render: this.renderColor
            },
            {name: 'actions', displayName: "Actions"},
        ];

        let data = [];
        grids.forEach((grid) => {
            data.push({
                id: grid.pk,
                createdAt: this.renderCreatedAt(grid),
                word: grid.word,
                status: this.renderStatus(grid),
                rows: grid.rows,
                columns: grid.columns,
                font: grid.font,
                color: grid.color,
                actions: this.renderActions(grid),
            });
        });

        return (<>
            <GoogleFontLoader
                fonts={this.generateFontsList()}
            />

            <FilterableTable
                namespace="puzzles"
                initialSort="id"
                data={data}
                fields={fields}
                noRecordsMessage={this.tableMessage}
                noFilteredRecordsMessage="No words match your filters."
                pagersVisible={false}
            />
        </>);
    }

    renderStatus(grid) {
        const now = new Date();
        const createdAt = new Date(grid.created_at);
        const difference = now.getTime() - createdAt.getTime();
        const resultInMinutes = Math.round(difference / 60000);

        if (grid.status === "Loading" && resultInMinutes > 5) {
            return "Timeout";
        } else {
            return grid.status;
        }
    }

    renderDownloadButton(link, text, color) {
        if (link) {
            return (<Button target="_blank" onClick={() => this.downloadGrid(link)}
                            color={color}>
                {text}
            </Button>);
        } else {
            return (<Button disabled={true} target="_blank" color={color}>
                {text}
            </Button>);
        }
    }

    renderDeleteButton(grid) {
        if (this.renderStatus(grid) !== "Loading") {
            return (<ConfirmRemovalModal
                pk={grid.pk}
                resetState={this.props.resetState}
            />);
        } else {
            return (<ConfirmRemovalModal
                disabled={true}
                pk={grid.pk}
                resetState={this.props.resetState}
            />);
        }
    }

    renderActions(grid) {
        return (<ButtonGroup>
            {this.renderDownloadButton(grid.without_solution_image, "PNG", "warning")}
            {this.renderDownloadButton(grid.with_solution_image, "PNG", "success")}
            {this.renderDownloadButton(grid.without_solution_svg, "SVG", "warning")}
            {this.renderDownloadButton(grid.with_solution_svg, "SVG", "success")}
            {this.renderDeleteButton(grid)}
        </ButtonGroup>);
    }

    renderColor(props) {
        return (<span style={{color: props.value}}>{props.value}</span>);
    }

    renderCreatedAt(grid) {
        return formatDistance(new Date(grid.created_at), new Date(), {addSuffix: true});
    }

    renderFont(props) {
        return (<span style={{fontFamily: props.value}}>{props.value}</span>)
    }

    generateFontsList() {
        let fontsListTemp = [];
        let fontsList = [];
        this.props.grids.forEach((grid) => {
            if (!fontsListTemp.includes(grid.font)) {
                fontsListTemp.push(grid.font);
                fontsList.push({
                    font: grid.font
                });
            }
        });

        return fontsList;
    }
}

export default GridList;
