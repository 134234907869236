import React from "react";
import {Alert, Button, Col, Form, FormGroup, FormText, Input, Label, Row} from "reactstrap";

import axios from "axios";

import {API_URL} from "../../constants";
import FontPicker from "font-picker-react";
import {CompactPicker} from "react-color";

class NewWordsForm extends React.Component {
    state = {
        words: [], color: "#FF0000", rows: 50, columns: 50, font: "Archivo Black",
        hasHeader: true, hasFooter: true,
        customFontFileBase64: "",
    };
    isError = false;
    fontFamilies = [
        'Secular One',
        'Lilita One',
        'Archivo Black',
        'Monoton',
        'Hanalei',
        'Fredericka the Great',
        'Londrina Shadow',
        'Roboto',
        'Open Sans',
        'Noto Sans JP',
        'Montserrat',
        'Lato',
        'Poppins',
        'Roboto Condensed',
        'Source Sans Pro',
        'Inter',
        'Roboto Mono',
        'Oswald',
        'Raleway',
        'Noto Sans',
        'Ubuntu',
        'Nunito Sans',
        'Roboto Slab',
        'Nunito',
        'Playfair Display',
        'Merriweather',
        'Rubik',
        'PT Sans',
        'Noto Sans KR',
        'Mukta',
        'Kanit',
        'Work Sans',
        'Lora',
        'Noto Sans TC',
        'Fira Sans',
        'Quicksand',
        'Barlow',
        'Mulish',
        'Inconsolata',
        'Heebo',
        'PT Serif',
        'Titillium Web',
        'IBM Plex Sans',
        'Hind Siliguri',
        'Noto Serif',
        'DM Sans',
        'Libre Franklin',
        'Manrope',
        'Nanum Gothic',
        'Karla',
        'Josefin Sans',
        'Arimo',
        'Source Serif Pro',
        'Libre Baskerville',
    ];

    onChange = e => {
        let newElements = e.target.value.split('\n').map(element => {
            return {
                pk: 0, word: element
            }
        });
        this.setState({
            words: newElements
        });
    };

    createGrid = e => {
        e.preventDefault();
        axios.post(API_URL, this.state).then(() => {
            this.props.resetState();
            this.props.toggle();
        }).catch(error => {
            this.props.resetState();
            if (error.response) {
                this.isError = true
                this.setState({error: "Error " + error.response.status});
            }
        });
    };

    handleColorChangeComplete = (color) => {
        this.setState({color: color.hex});
    };

    onRowsChange = (e) => {
        this.setState({rows: e.target.value});
    };

    onColumnsChange = (e) => {
        this.setState({columns: e.target.value});
    };

    renderErrorMessage = () => {
        if (this.isError) {
            return (<Alert
                color="danger"
                isError={true}
            >
                {this.state.error}
            </Alert>)
        }
    }

    onCustomFontFileSelected = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
            this.setState({'customFontFileBase64': reader.result})
        }.bind(this);
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    render() {
        return (<Form onSubmit={this.createGrid}>
            <FormGroup>
                <Label for="name">Words:</Label>
                <Input
                    type="textarea"
                    name="words"
                    onChange={this.onChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for="rows">Rows:</Label>
                <Input
                    name="rows"
                    type="number"
                    min="10"
                    max="50"
                    value={this.state.rows}
                    onChange={this.onRowsChange}
                />
            </FormGroup>
            <FormGroup>
                <Label for="columns">Columns:</Label>
                <Input
                    name="columns"
                    type="number"
                    min="10"
                    max="50"
                    value={this.state.columns}
                    onChange={this.onColumnsChange}
                />
            </FormGroup>
            <FormGroup check inline>
                <Input type="checkbox"
                       name="hasHeader"
                       checked={this.state.hasHeader}
                       onChange={this.onHasHeaderChange}/>
                <Label check for="hasHeader">
                    Puzzle Header
                </Label>
            </FormGroup>
            <FormGroup check inline>
                <Input type="checkbox"
                       name="hasFooter"
                       checked={this.state.hasFooter}
                       onChange={this.onHasFooterChange}/>
                <Label check for="hasFooter">
                    Puzzle Footer
                </Label>
            </FormGroup>
            <Row>
                <Col hidden={this.state.customFontFileBase64 !== ""}>
                    <FormGroup>
                        <FontPicker
                            apiKey="AIzaSyCWcLor49zIT9qyjQsz0IQhiojde1ZS1QI"
                            activeFontFamily={this.state.font}
                            families={this.fontFamilies}
                            sort="popularity"
                            limit="60"
                            onChange={(nextFont) => this.setState({
                                font: nextFont.family,
                            })}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Input
                            name="customFontFile"
                            type="file"
                            onChange={this.onCustomFontFileSelected}
                            accept=".ttf,.otf,.woff,.woff2"
                        />
                        <FormText>
                            Custom Font File
                        </FormText>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <CompactPicker
                    color={this.state.color}
                    onChangeComplete={this.handleColorChangeComplete}
                />
            </FormGroup>
            {this.renderErrorMessage()}
            <Button>Submit</Button>
        </Form>);
    }

    onHasHeaderChange = (e) => {
        this.setState({hasHeader: e.target.checked})
    }

    onHasFooterChange = (e) => {
        this.setState({hasFooter: e.target.checked})
    }
}

export default NewWordsForm;
