import {NEW_PASSWORD_USER_ERROR, NEW_PASSWORD_USER_SUBMITTED, NEW_PASSWORD_USER_SUCCESS} from "./PasswordResetTypes";
import axios from "axios";
import {toast} from "react-toastify";
import {push} from "connected-react-router";
import {SET_USERNAME} from "../login/LoginTypes";

export const resetPassword = userData => dispatch => {
    dispatch({type: NEW_PASSWORD_USER_SUBMITTED});
    axios
        .post("/api/v1/users/reset_password_confirm/", userData)
        .then((response) => {
            const returned_user_data = response.data;
            toast.success(
                "Password for " +
                returned_user_data.username +
                " reset successfully. Please login."
            );
            dispatch({type: NEW_PASSWORD_USER_SUCCESS});
            dispatch({
                type: SET_USERNAME,
                payload: returned_user_data.username,
            });
            dispatch(push("/login"));
        })
        .catch(error => {
            if (error.response) {
                dispatch({
                    type: NEW_PASSWORD_USER_ERROR,
                    errorData: error.response.data
                });
            } else if (error.message) {
                toast.error(JSON.stringify(error.message));
            } else {
                toast.error(JSON.stringify(error));
            }
        });
};
