import {NEW_PASSWORD_USER_ERROR, NEW_PASSWORD_USER_SUBMITTED, NEW_PASSWORD_USER_SUCCESS} from "./PasswordResetTypes";

const initialState = {
    newPasswordError: "",
    licenseKeyError: "",
    isSubmitted: false,
};

export const passwordResetReducer = (state = initialState, action) => {
    switch (action.type) {
        case NEW_PASSWORD_USER_SUBMITTED:
            return {
                newPasswordError: "",
                licenseKeyError: "",
                isSubmitted: true
            };
        case NEW_PASSWORD_USER_ERROR:
            const errorState = {
                newPasswordError: "",
                licenseKeyError: "",
                isSubmitted: false
            };
            if (action.errorData.hasOwnProperty("new_password")) {
                errorState.newPasswordError = action.errorData["new_password"];
            }
            if (action.errorData.hasOwnProperty("licenseKey")) {
                errorState.licenseKeyError = action.errorData["licenseKey"];
            }
            return errorState;
        case NEW_PASSWORD_USER_SUCCESS:
            return {
                newPasswordError: "",
                licenseKeyError: "",
                isSubmitted: false
            };
        default:
            state.licenseKeyError = ""
            state.newPasswordError = ""
            return state;
    }
}
