import axios from "axios";
import {LOGIN_ERROR, SET_CURRENT_USER, SET_TOKEN, UNSET_CURRENT_USER} from "./LoginTypes";
import {push} from "connected-react-router";
import {toast} from "react-toastify";
import {setAxiosAuthToken, toastOnError} from "../../utils/Utils";

export const login = (userData, redirectTo) => dispatch => {
    axios
        .post("/api/v1/token/login/", userData)
        .then(response => {
            const {auth_token} = response.data;
            setAxiosAuthToken(auth_token);
            dispatch(setToken(auth_token));
            dispatch(getCurrentUser(redirectTo));
        })
        .catch(error => {
            dispatch(unsetCurrentUser());
            if (error.response) {
                dispatch({
                    type: LOGIN_ERROR,
                    errorData: error.response.data
                });
                if (error.response.data.hasOwnProperty("non_field_errors")) {
                    toast.error(error.response.data["non_field_errors"].join("\r\n"));
                }
            } else if (error.message) {
                toast.error(JSON.stringify(error.message));
            } else {
                toast.error(JSON.stringify(error));
            }
        });
};

export const getCurrentUser = redirectTo => dispatch => {
    axios
        .get("/api/v1/users/me/")
        .then(response => {
            const user = {
                username: response.data.username,
                email: response.data.email
            };
            dispatch(setCurrentUser(user, redirectTo));
        })
        .catch(() => {
            dispatch(unsetCurrentUser());
        });
};

export const setCurrentUser = (user, redirectTo) => dispatch => {
    localStorage.setItem("user", JSON.stringify(user));
    dispatch({
        type: SET_CURRENT_USER,
        payload: user
    });

    if (redirectTo !== "") {
        dispatch(push(redirectTo));
    }
};

export const setToken = token => dispatch => {
    setAxiosAuthToken(token);
    localStorage.setItem("token", token);
    dispatch({
        type: SET_TOKEN,
        payload: token
    });
};

export const unsetCurrentUser = () => dispatch => {
    setAxiosAuthToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch({
        type: UNSET_CURRENT_USER
    });
};

export const logout = () => dispatch => {
    axios
        .post("/api/v1/token/logout/")
        .then(response => {
            dispatch(unsetCurrentUser());
            dispatch(push("/"));
            toast.success("Logout successful.");
        })
        .catch(error => {
            dispatch(unsetCurrentUser());
            toastOnError(error);
        });
};
