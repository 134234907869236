import {CREATE_USER_ERROR, CREATE_USER_SUBMITTED, CREATE_USER_SUCCESS} from "./SignupTypes";

const initialState = {
    usernameError: "",
    passwordError: "",
    licenseKeyError: "",
    isSubmitted: false
};

export const signupReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_USER_SUBMITTED:
            return {
                usernameError: "",
                passwordError: "",
                licenseKeyError: "",
                isSubmitted: true
            };
        case CREATE_USER_ERROR:
            const errorState = {
                usernameError: "",
                passwordError: "",
                licenseKeyError: "",
                isSubmitted: false
            };
            if (action.errorData.hasOwnProperty("username")) {
                errorState.usernameError = action.errorData["username"];
            }
            if (action.errorData.hasOwnProperty("password")) {
                errorState.passwordError = action.errorData["password"];
            }
            if (action.errorData.hasOwnProperty("licenseKey")) {
                errorState.licenseKeyError = action.errorData["licenseKey"];
            }
            return errorState;
        case CREATE_USER_SUCCESS:
            return {
                usernameError: "",
                passwordError: "",
                licenseKeyError: "",
                isSubmitted: false
            };
        default:
            state.licenseKeyError = ""
            state.passwordError = ""
            state.usernameError = ""
            return state;
    }
}
