import React, {Component, Fragment} from "react";
import {Button, ButtonGroup, Col, Container, Row, UncontrolledAlert} from "reactstrap";
import GridList from "./GridList";

import axios from "axios";

import {API_URL} from "../../constants";
import NewWordsModal from "./NewWordsModal";
import ConfirmAllRemovalModal from "./ConfirmAllRemovalModal";
import DownloadAllButton from "./DownloadAllButton";
import Header from "../Header";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {logout} from "../login/LoginActions";
import {withRouter} from "react-router-dom";

class Dashboard extends Component {
    state = {
        grids: []
    };

    componentDidMount() {
        this.resetState();
    }

    getGrids = () => {
        axios.get(API_URL).then(res => this.setState({grids: res.data}));
    };

    resetState = () => {
        this.getGrids();
    };

    onLogout = () => {
        this.props.logout();
    };

    render() {
        const {user} = this.props.auth;

        return (
            <Fragment>
                <Header user={user} onLogout={this.onLogout}/>
                <Container style={{marginTop: 10}}>
                    <Row>
                        <Col>
                            <UncontrolledAlert color="warning">
                                Please delete after downloading. All generated puzzles will be deleted automatically
                                from the system 30 days after creation to declutter the server.
                            </UncontrolledAlert>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ButtonGroup>
                                <NewWordsModal resetState={this.resetState}/>
                                {this.renderDownloadAllButton(this.state.grids)}
                                {this.renderDeleteAllButton(this.state.grids)}
                                {this.renderRefreshButton()}
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <GridList
                                grids={this.state.grids}
                                resetState={this.resetState}
                                refreshGrids={this.getGrids}
                            />
                        </Col>
                    </Row>
                </Container>
            </Fragment>);
    }

    renderDownloadAllButton(grids) {
        if (grids.length !== 0) {
            return (<DownloadAllButton resetState={this.resetState}/>)
        } else {
            return (<DownloadAllButton resetState={this.resetState} disabled={true}/>)
        }
    }

    renderDeleteAllButton(grids) {
        if (grids.length !== 0) {
            return (<ConfirmAllRemovalModal resetState={this.resetState}/>)
        } else {
            return (<ConfirmAllRemovalModal resetState={this.resetState} disabled={true}/>)
        }
    }

    renderRefreshButton() {
        return (<Button color="info" outline onClick={() => this.getGrids()}>
            🔄 Refresh
        </Button>);
    }
}

Dashboard.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {
    logout
})(withRouter(Dashboard));
