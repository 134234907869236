import React, {Component, Fragment} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {resetPassword} from "./PasswordResetActions";
import Header from "../Header";

class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: "",
            licenseKey: "",
        };
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    onResetPasswordClick = e => {
        e.preventDefault();
        const userData = {
            new_password: this.state.newPassword,
            licenseKey: this.state.licenseKey
        };
        this.props.resetPassword(userData);
    };

    render() {
        return (
            <Fragment>
                <Header/>
                <Container>
                    <Row>
                        <Col md="4">
                            <h1>Password Reset</h1>
                            <Form onSubmit={this.onResetPasswordClick}>
                                <Form.Group className="mb-3" controlId="passwordId">
                                    <Form.Label>New password</Form.Label>
                                    <Form.Control
                                        isInvalid={this.props.newPasswordUser.newPasswordError}
                                        type="password"
                                        name="newPassword"
                                        placeholder="Enter new password"
                                        value={this.newPassword}
                                        onChange={this.onChange}
                                    />
                                    <Form.Control.Feedback type="invalid" style={{whiteSpace: 'pre-wrap'}}>
                                        {Array.isArray(this.props.newPasswordUser.newPasswordError)
                                            ? this.props.newPasswordUser.newPasswordError.join("\r\n")
                                            : this.props.newPasswordUser.newPasswordError}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="licenseKeyId">
                                    <Form.Label>License key</Form.Label>
                                    <Form.Control
                                        isInvalid={this.props.newPasswordUser.licenseKeyError}
                                        type="text"
                                        name="licenseKey"
                                        placeholder="Enter license key"
                                        value={this.licenseKey}
                                        onChange={this.onChange}
                                    />
                                    <Form.Control.Feedback type="invalid" style={{whiteSpace: 'pre-wrap'}}>
                                        {Array.isArray(this.props.newPasswordUser.licenseKeyError)
                                            ? this.props.newPasswordUser.licenseKeyError.join("\r\n")
                                            : this.props.newPasswordUser.licenseKeyError}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Reset Password
                                </Button>

                                <p className="mt-2">
                                    Already have account? <Link to="/login">Login</Link>
                                </p>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

PasswordReset.propTypes = {
    resetPassword: PropTypes.func.isRequired,
    newPasswordUser: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    newPasswordUser: state.newPasswordUser
});

export default connect(mapStateToProps, {
    resetPassword
})(withRouter(PasswordReset));
