import React, {Component} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Login from "./components/login/Login";
import Dashboard from "./components/dashboard/Dashboard";
import Root from "./Root";
import requireAuth from "./utils/RequireAuth";
import {ToastContainer} from "react-toastify";

import './App.css';
import Signup from "./components/signup/Signup";
import PasswordReset from "./components/passwordReset/PasswordReset";

class App extends Component {
    render() {
        return (
            <Root>
                <ToastContainer hideProgressBar={true} newestOnTop={true}/>
                <Switch>
                    <Route path="/signup" component={Signup}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/password-reset" component={PasswordReset}/>
                    <Route path="/dashboard" component={requireAuth(Dashboard)}/>
                    <Route exact path="/">
                        <Redirect to="/dashboard"/>
                    </Route>
                </Switch>
            </Root>
        );
    }
}

export default App;
