import React, {Component} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";

class Header extends Component {
    renderUserInfo = () => {
        if (this.props.user) {
            return (<Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                    User: <b>{this.props.user.username}</b>
                </Navbar.Text>
                <Nav.Link onClick={this.props.onLogout}>Logout</Nav.Link>
            </Navbar.Collapse>);
        }
    }

    render() {
        return (<Navbar bg="light">
            <Container>
                <Navbar.Brand href="/">Single Word Word Search Generator</Navbar.Brand>
                <Navbar.Toggle/>
                {this.renderUserInfo()}
            </Container>
        </Navbar>);
    }
}

export default Header;
