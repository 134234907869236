import React, {Component, Fragment} from "react";
import {Button, Col, Container, Form, FormControl, Row} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {signupNewUser} from "./SignupActions";
import Header from "../Header";

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            licenseKey: "",
        };
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value});
    };

    onSignupClick = e => {
        e.preventDefault();
        const userData = {
            username: this.state.username,
            password: this.state.password,
            licenseKey: this.state.licenseKey
        };
        this.props.signupNewUser(userData);
    };

    render() {
        return (
            <Fragment>
                <Header/>
                <Container>
                    <Row>
                        <Col md="4">
                            <h1>Sign Up</h1>
                            <Form onSubmit={this.onSignupClick}>
                                <Form.Group className="mb-3" controlId="usernameId">
                                    <Form.Label>User name</Form.Label>
                                    <Form.Control
                                        isInvalid={this.props.createUser.usernameError}
                                        type="text"
                                        name="username"
                                        placeholder="Enter user name"
                                        value={this.state.username}
                                        onChange={this.onChange}
                                    />
                                    <FormControl.Feedback type="invalid" style={{whiteSpace: 'pre-wrap'}}>
                                        {Array.isArray(this.props.createUser.usernameError)
                                            ? this.props.createUser.usernameError.join("\r\n")
                                            : this.props.createUser.usernameError}
                                    </FormControl.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="passwordId">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        isInvalid={this.props.createUser.passwordError}
                                        type="password"
                                        name="password"
                                        placeholder="Enter password"
                                        value={this.password}
                                        onChange={this.onChange}
                                    />
                                    <Form.Control.Feedback type="invalid" style={{whiteSpace: 'pre-wrap'}}>
                                        {Array.isArray(this.props.createUser.passwordError)
                                            ? this.props.createUser.passwordError.join("\r\n")
                                            : this.props.createUser.passwordError}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="licenseKeyId">
                                    <Form.Label>License key</Form.Label>
                                    <Form.Control
                                        isInvalid={this.props.createUser.licenseKeyError}
                                        type="text"
                                        name="licenseKey"
                                        placeholder="Enter license key"
                                        value={this.licenseKey}
                                        onChange={this.onChange}
                                    />
                                    <Form.Control.Feedback type="invalid" style={{whiteSpace: 'pre-wrap'}}>
                                        {Array.isArray(this.props.createUser.licenseKeyError)
                                            ? this.props.createUser.licenseKeyError.join("\r\n")
                                            : this.props.createUser.licenseKeyError}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Sign up
                                </Button>

                                <p className="mt-2">
                                    Already have account? <Link to="/login">Login</Link>
                                </p>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

Signup.propTypes = {
    signupNewUser: PropTypes.func.isRequired,
    createUser: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    createUser: state.createUser
});

export default connect(mapStateToProps, {
    signupNewUser
})(withRouter(Signup));
