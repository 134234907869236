import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {signupReducer} from "./components/signup/SignupReducer";
import {loginReducer} from "./components/login/LoginReducer";
import {passwordResetReducer} from "./components/passwordReset/PasswordResetReducer";

const rootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        createUser: signupReducer,
        newPasswordUser: passwordResetReducer,
        auth: loginReducer,
    });

export default rootReducer;
