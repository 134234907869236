import thunk from "redux-thunk";
import {ConnectedRouter, routerMiddleware} from "connected-react-router";
import {applyMiddleware, createStore} from "redux";
import {Provider} from "react-redux";
import {createBrowserHistory} from "history";
import rootReducer from "./Reducer";
import {isEmpty} from "./utils/Utils";
import {setCurrentUser, setToken} from "./components/login/LoginActions";

export default ({children, initialState = {}}) => {
    const history = createBrowserHistory();
    const middleware = [thunk, routerMiddleware(history)];

    const store = createStore(
        rootReducer(history),
        initialState,
        applyMiddleware(...middleware)
    );

    if (!isEmpty(localStorage.getItem("token"))) {
        store.dispatch(setToken(localStorage.getItem("token")));
    }
    if (!isEmpty(localStorage.getItem("user"))) {
        const user = JSON.parse(localStorage.getItem("user"));
        store.dispatch(setCurrentUser(user, ""));
    }

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>{children}</ConnectedRouter>
        </Provider>
    );
};
